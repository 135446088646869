<template>
  <div>
    <loading v-if="pageLoading" />
    

    <div v-show="!pageLoading" class="container">
      <navigation-component :id="2" />
      <div class="page-row">
        <img class="bg" src="../../assets/oss/pc_about_pg_bg.jpg" />
        <div class="content">
          <img class="bg" src="../../assets/oss/pc_about_content_bg.png" style="width: 100%; height: 100%;" />
          <div class="page-title">
            联系我们
            <div class="line"></div>
          </div>
          <div class="page-content">
            <div class="column animate__animated animate__fadeInUp" data-aos="fade-up" data-aos-delay="200">
              <div class="item">
                <div class="title">客服QQ</div>
                <div class="detail">2782487067</div>
              </div>
              <div class="item">
                <div class="title">官方邮箱</div>
                <div class="detail">service@517date.com</div>
              </div>
              <div class="item">
                <div class="title">商务合作</div>
                <div class="detail">2782487067@qq.com</div>
              </div>
            </div>
            <div class="column animate__animated animate__fadeInUp ani-delay-01s">
              <div class="title-item">公众号</div>
              <div class="sub-title-item">关注偶遇圈公众号</div>
              <div class="qrcode-item">
                <img src="../../assets/images/pc/weixin.png" />
              </div>
            </div>
            <div class="column animate__animated animate__fadeInUp ani-delay-02s" data-aos="fade-up" data-aos-delay="600">
              <div class="title-item">抖音号</div>
              <div class="sub-title-item">关注偶遇圈抖音号</div>
              <div class="qrcode-item">
                <img src="../../assets/images/pc/douyin.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer-component />
    </div>
  </div>
</template>

<script>

import NavigationComponent from '../../components/NavigationComponent.vue'
import FooterComponent from '../../components/FooterComponent.vue'
import Loading from '../../components/Loading.vue'
import { checkImg } from '../../utils/checkImg'
import "animate.css"

export default {
  name: 'About',
  metaInfo: {
    title: '关于我们 - 偶遇圈',
    meta: [
      {
        name: 'description',
        content: '关于我们，偶遇圈app，是一个为单身男女提供，附近高颜值、高素质的真人交友平台！'
      },
      {
        name: 'keywords',
        content: '偶遇、艳遇、邂逅、偶遇圈、偶遇圈app、偶遇圈下载、同城交友、附近约会'
      }
  ]
  },
  components: {
    NavigationComponent,
    FooterComponent,
    Loading
  },
  data () {
    return {
      pageLoading: true,
      loginVis: 0
    }
  },
  mounted () {
    checkImg(() => {
      this.pageLoading = false
    })
    
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.page-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1098px;
  .content {
    position: relative;
    z-index: 1;
    width: 1250px;
    height: 766px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .page-title {
      position: relative;
      z-index: 1;
      font-size: 34px;
      color: #ffd321;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .line {
        width: 42px;
        height: 4px;
        background-color: #ffd321;
        margin-top: 20px;
      }
    }
    .page-content {
      position: relative;
      z-index: 1;
      margin-top: 60px;
      width: 1055px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .column {
        background-color: rgba(0, 0, 0, .3);
        border-radius: 5px;
        width: 313px;
        height: 487px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #fff;
        line-height: 39px;
        .item {
          margin-bottom: 50px;
          text-align: center;
          .title {
            font-size: 23px;
          }
          .detail {
            font-size: 20px;
          }
        }
        .title-item {
          font-size: 23px;
          color: #ffd321;
        }
        .sub-title-item {
          font-size: 19px;
        }
        .qrcode-item {
          width: 188px;
          height: 188px;
          overflow: hidden;
          background-color: #fff;
          margin-top: 70px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      .ani-delay-01s {
        --animate-delay: 0.1s;
      }
      .ani-delay-02s {
        --animate-delay: 0.2s;
      }
    }
  }
}
</style>
